// src/VCardGenerator.js
import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './App.css';

function VCardGenerator() {
  const [vCard, setVCard] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
  });
  const [qrVCard, setQrVCard] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVCard((prevVCard) => ({ ...prevVCard, [name]: value }));
  };

  const handleClick = () => {
    const vCardString = `BEGIN:VCARD\nVERSION:3.0\nN:${vCard.lastName};${vCard.firstName}\nTEL:${vCard.phone}\nEMAIL:${vCard.email}\nADR:${vCard.address}\nEND:VCARD`;
    setQrVCard(vCardString);
    setIsInputDisabled(true);
  };

  const handleReset = () => {
    setVCard({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
    });
    setQrVCard('');
    setIsInputDisabled(false);
    window.location.reload();
  };

  const handleDownload = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'vcard-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

return (
    <div className="App">
        <header className="App-header">
            <h1>vCard QR Code Generator</h1>
            <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={vCard.firstName}
                onChange={handleChange}
                disabled={isInputDisabled}
            />
            <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={vCard.lastName}
                onChange={handleChange}
                disabled={isInputDisabled}
            />
            <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={vCard.phone}
                onChange={handleChange}
                disabled={isInputDisabled}
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={vCard.email}
                onChange={handleChange}
                disabled={isInputDisabled}
            />
            <input
                type="text"
                name="address"
                placeholder="Address"
                value={vCard.address}
                onChange={handleChange}
                disabled={isInputDisabled}
            />
            <button
                onClick={handleClick}
                style={{
                    backgroundColor: 'white', // Changed the button color to white
                    color: 'black', // Changed the text color to black
                    padding: '10px 20px',
                    borderRadius: '5px',
                }}
                disabled={
                    !vCard.firstName ||
                    !vCard.lastName ||
                    !vCard.phone ||
                    !vCard.email ||
                    !vCard.address
                }
            >
                Generate vCard QR Code
            </button>
            <button
                onClick={handleReset}
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    marginLeft: '10px',
                }}
            >
                Reset
            </button>
            {qrVCard && <QRCode value={qrVCard} size={300} />}
            {qrVCard && (
                <button
                    onClick={handleDownload}
                    style={{
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    Download QR Code
                </button>
            )}
        </header>
    </div>
);
}

export default VCardGenerator;