// src/PlantGenerator.js
import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import './App.css';

function PlantGenerator() {
    const [plant, setPlant] = useState({
        commonName: '',
        botanicalName: '',
    });
    const [qrPlant, setQrPlant] = useState('');
    const [isInputDisabled, setIsInputDisabled] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlant((prevPlant) => ({ ...prevPlant, [name]: value }));
    };

    const handleClick = () => {
        const plantString = `Common Name: ${plant.commonName}\nBotanical Name: ${plant.botanicalName}`;
        setQrPlant(plantString);
        setIsInputDisabled(true);
    };

    const handleReset = () => {
        setPlant({
            commonName: '',
            botanicalName: '',
        });
        setQrPlant('');
        setIsInputDisabled(false);
        window.location.reload();
    };

    const handleDownload = () => {
        const canvas = document.querySelector('canvas');
        const pngUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'plant-qr-code.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>Plant QR Code Generator</h1>
                <input
                    type="text"
                    name="commonName"
                    placeholder="Common Name"
                    value={plant.commonName}
                    onChange={handleChange}
                    disabled={isInputDisabled}
                />
                <input
                    type="text"
                    name="botanicalName"
                    placeholder="Botanical Name"
                    value={plant.botanicalName}
                    onChange={handleChange}
                    disabled={isInputDisabled}
                />
                <button onClick={handleClick} style={{ backgroundColor: 'blue', color: 'white', padding: '10px 20px', borderRadius: '5px' }}>
                    Generate Plant QR Code
                </button>
                <button onClick={handleReset} style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', marginLeft: '10px' }}>
                    Reset
                </button>
                {qrPlant && <QRCode value={qrPlant} size={300} />}
                {qrPlant && <button onClick={handleDownload} style={{ backgroundColor: 'green', color: 'white', padding: '10px 20px', borderRadius: '5px', marginTop: '10px' }}>
                    Download QR Code
                </button>}
            </header>
        </div>
    );
}

export default PlantGenerator;