import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import QRCode from 'qrcode.react';
import VCardGenerator from './VCardGenerator';
import './App.css';
import PlantGenerator from './PlantGenerator';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  const [url, setUrl] = useState('');
  const [qrUrl, setQrUrl] = useState('');
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const handleChange = (e) => {
    setUrl(e.target.value);
  };

  const handleClick = () => {
    setQrUrl(url);
    setIsInputDisabled(true);
  };

  const handleReset = () => {
    setUrl('');
    setQrUrl('');
    setIsInputDisabled(false);
    window.location.reload();
  };

  const handleDownload = () => {
    const canvas = document.querySelector('canvas');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Router>
      <div className="App">
      <Analytics />
      <SpeedInsights />
        {/* <nav>
          {window.location.pathname === '/' && (
            <button style={{ position: 'absolute', top: 0, right: 0 }}>
              <Link to="/vcard">vCard QR Code Generator</Link>
            </button>
          )}
        </nav> */}
        <Routes>
          <Route path="/vcard" element={<VCardGenerator />} />
          <Route path="/plants" element={<PlantGenerator />} />
          <Route
            path="/"
            element={
              <header className="App-header">
                <h1>QR Code Generator</h1>
                <input
                  type="text"
                  placeholder="Enter URL"
                  value={url}
                  onChange={handleChange}
                  disabled={isInputDisabled}
                />
                <button onClick={handleClick}>Generate QR Code</button>
                <button onClick={handleReset}>Reset</button>
                {qrUrl && <QRCode value={qrUrl} size={300} />}
                {qrUrl && <button onClick={handleDownload}>Download QR Code</button>}
              </header>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;